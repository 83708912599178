export const hours = [
    {
        day: "Monday",
        open: "4:00 PM",
        closed: "2:00 AM",
    },
    {
        day: "Tuesday",
        open: "4:00 PM",
        closed: "2:00 AM",
    },
    {
        day: "Wednesday",
        open: "4:00 PM",
        closed: "2:00 AM",
    },
    {
        day: "Thursday",
        open: "4:00 PM",
        closed: "2:00 AM",
    },
    {
        day: "Friday",
        open: "4:00 PM",
        closed: "2:00 AM",
    },
    {
        day: "Saturday",
        open: "4:00 PM",
        closed: "2:00 AM",
    },
    {
        day: "Sunday",
        open: "4:00 PM",
        closed: "2:00 AM",
    },
]
